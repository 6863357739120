<template>
  <b-modal ref="modal" centered :title="modalTitle" hide-footer @hide="resetForm">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(handleAddEditProduct)">
        <b-form-group label="الاسم" label-for="name-input">
          <ValidationProvider name="الاسم" rules="required" v-slot="{ errors }">
            <b-form-input id="name-input" v-model="product.name" :state="errors.length === 0 ? null : false"></b-form-input>
            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
          </ValidationProvider>
        </b-form-group>
        <b-form-group label="السعر" label-for="price-input">
          <ValidationProvider name="السعر" rules="required|numeric|min_value:1" v-slot="{ errors }">
            <b-form-input id="price-input" v-model="product.price" type="number" :state="errors.length === 0 ? null : false"></b-form-input>
            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
          </ValidationProvider>
        </b-form-group>
        <b-form-group label="الوصف" label-for="description-input">
            <b-form-textarea id="description-input" v-model="product.description"></b-form-textarea>
        </b-form-group>
        <footer class="text-right modal-footer">
          <b-button type="submit" variant="primary">{{ isEditMode ? 'تعديل' : 'اضافة' }}</b-button>
          <b-button type="button" variant="secondary" @click="hideModal">الغاء</b-button>
        </footer>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import { mapActions } from 'vuex'
import { BFormGroup, BFormInput, BFormTextarea, BButton, BModal, BFormInvalidFeedback } from 'bootstrap-vue'
import { extend } from 'vee-validate'
import { required, min, min_value, numeric } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BModal,
    BFormInvalidFeedback
  },
  props: {
    isEditMode: {
      type: Boolean,
      default: false
    },
    initialProduct: {
      type: Object,
      default: () => ({
        id: 0,
        name: '',
        price: 0,
        description: ''
      })
    },
    modalTitle: {
      type: String,
      default: 'اضافة منتج'
    }
  },
  data() {
    return {
      locale: 'ar',
      product: { ...this.initialProduct }
    }
  },
  mounted() {
    // Switch to Arabic in validation
    localize(this.locale)
  },
  watch: {
    initialProduct(newProduct) {
      this.product = { ...newProduct }
    }
  },
  methods: {
    ...mapActions('products', ['createProduct', 'updateProduct']),
    showModal() {
      this.$refs.modal.show()
    },
    hideModal() {
      this.$refs.modal.hide()
    },

    handleAddEditProduct() {
      this.$refs.observer.validate().then((success) => {
        if (success) {
          const action = this.isEditMode ? 'updateProduct' : 'createProduct'
          this[action](this.product).then(() => {
            this.$emit('product-saved', this.product)
            this.$refs.modal.hide()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'تم التنفيذ بنجاح',
                icon: 'CheckIcon',
                variant: 'success'
              }
            })
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'خطأ في السيرفر',
              icon: 'TrashIcon',
              variant: 'danger'
            }
          })
        }
      })
    },
    resetForm() {
      this.product = { ...this.initialProduct }
    }
  }
}
</script>

<style scoped>
::v-deep .modal-title {
  font-size: 1.6rem;
  font-weight: 400;
}
/* Add your custom styles here */
</style>
