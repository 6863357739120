<template>
  <b-card-code title="">
    <b-button style="margin-top: -60px; float: right" variant="primary" @click="showAddModal">إضافة منتج</b-button>
    <vue-good-table
      :columns="columns"
      :rows="this.products"
      :rtl="direction"
      :lineNumbers="true"
      :search-options="{
        enabled: true
      }"
      :paginationOptions="{
        enabled: true,
        perPage: pageSize
      }"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'" class="text-nowrap">
          <!-- style two button for edit and delete -->
          <div class="d-flex justify-content-start">
            <button class="btn btn-primary mr-1" @click="showEditModal(props.row)">
              <feather-icon icon="EditIcon" size="16" />
            </button>
            <DeleteModel :displayName="props.row.name" @confirm-delete="handleDelete(props.row.id)" />
          </div>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template slot="pagination-bottom" slot-scope="">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> عرض الصفحة الأولى </span>

            <b-form-select v-model="pageSize" :options="['3', '7', '10', '15', '20']" class="mx-1" @change="onPerPageChange" />
            <span class="text-nowrap"> من {{ totalCount }} العناصر </span>
          </div>
          <div>
            <b-pagination
              v-model="pageNumber"
              :total-rows="totalCount"
              :per-page="pageSize"
              first-number
              last-number
              align="right"
              class="mt-1 mb-0"
              @input="handlePageChange"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <AddProduct
      ref="addEditProductModal"
      :isEditMode="isEditMode"
      :initialProduct="selectedProduct"
      :modalTitle="modalTitle"
      @product-saved="handleProductSaved"
    />
  </b-card-code>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DeleteModel from '@core/components/DeleteModel.vue'
import AddProduct from './AddProduct.vue'
import store from '@/store'
import {
  BButton,
  BAvatar,
  BBadge,
  BModal,
  VBModal,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormDatepicker
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BAvatar,
    BBadge,
    BModal,
    BCardCode,
    DeleteModel,
    VBModal,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    VueGoodTable,
    AddProduct
  },
  data() {
    return {
      isEditMode: false,
      selectedProduct: {
        id: 0,
        name: '',
        price: 0,
        description: ''
      },
      modalTitle: 'اضافة منتج',

      pageSize: 7,
      pageNumber: 1,

      dir: false,
      columns: [
        {
          label: 'الاسم',
          field: 'name',
          sortable: true
        },
        {
          label: 'السعر',
          field: 'price',
          sortable: false
        },
        {
          label: 'الوصف',
          field: 'description',
          sortable: false
        },
        {
          label: 'التفاصيل',
          field: 'action',
          sortable: false
        }
      ]
    }
  },
  async mounted() {
    await this.getProductsFromGrid()
  },

  methods: {
    ...mapActions('products', ['fetchProductsGrid', 'deleteProduct']),

    async getProductsFromGrid() {
      await this.fetchProductsGrid({ pageSize: this.pageSize, pageNumber: this.pageNumber })
    },

    showAddModal() {
      this.isEditMode = false
      this.selectedProduct = {
        id: 0,
        name: '',
        price: 0,
        description: ''
      }
      this.modalTitle = 'اضافة منتج'
      this.$refs.addEditProductModal.showModal()
    },
    showEditModal(product) {
      this.isEditMode = true
      this.selectedProduct = { ...product }
      this.modalTitle = 'تعديل منتج'
      this.$refs.addEditProductModal.showModal()
    },

    handleProductSaved(product) {
      // Handle product saved event (e.g., refresh product list)
      // console.log('Product saved:', product)
    },
    async handlePageChange() {
      await this.getProductsFromGrid()
    },

    async onPerPageChange(newPerPage) {
      this.pageSize = parseInt(newPerPage, 10)
      await this.getProductsFromGrid(this.pageSize, this.pageNumber)
    },
    //add toster when delete product is done and if not done show error message
    async handleDelete(id) {
      await this.deleteProduct(id)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم الحذف بنجاح',
              icon: 'TrashIcon',
              variant: 'success'
            }
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'حدث خطأ أثناء الحذف',
              icon: 'XCircleIcon',
              variant: 'danger'
            }
          })
        })
    }
  },
  computed: {
    ...mapGetters('products', {
      products: 'getProducts',
      totalCount: 'totalCountData',
      pagesCount: 'pagesCount'
    }),

    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true
        return this.dir
      }
      this.dir = false
      return this.dir
    }
  }
}
</script>

<style>
/* Add your custom styles here */
</style>
